import './SearchBar.css';
import React from 'react';

class SearchBar extends React.Component {
  state = { term: '' };

  onFormSubmit = event => {
    event.preventDefault();

    // console.log(this.state.term)
    this.props.onSubmit(this.state.term);
  }

  render() {
    const urlhome = 'https://host.likethai.com'
    const urlimage = 'https://host.likethai.com/?tp=1'
    const urllogin = 'https://usr.likethai.com/login'
    const urlabout = 'https://usr.likethai.com/about'


    return (
      <div className="ui segment" style={{ background: "#5A5A5A", borderWidth: 0, padding: 0, marginTop: 20, marginBottom: 20 }}>
        <form onSubmit={this.onFormSubmit} className="ui form">
          <div className="field">
            <label><a className="link" href="https://usr.likethai.com/">LikeThai</a>&nbsp;&nbsp;&nbsp;<a className="linkNamn" href={urlhome}>Articles</a>&nbsp;&nbsp;&nbsp;<a className="linkNamn" href={urlimage}>Images</a>&nbsp;&nbsp;&nbsp;<a className="linkNamn" href={urlabout}>About</a>&nbsp;&nbsp;&nbsp;<a className="linkNamn" href={urllogin}>Login</a></label>
            <input maxLength="70" style={{ background: "#5A5A5A", color: "#DCDCDC", fontSize: 16 }}
              type="text"
              placeholder="Search"
              value={this.state.term}
              onChange={e => this.setState({ term: e.target.value })}
            />
          </div>
        </form>
      </div >
    );
  }
}

export default SearchBar;