import React from 'react';

// var count = 0

class ImageCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = { spans: 0 };

    this.imageRef = React.createRef();

    // console.log('imageRef')
    // console.log(this.imageRef)
  }

  componentDidMount() {
    this.imageRef.current.addEventListener('load', this.setSpans)
  }

  setSpans = () => {
    const height = this.imageRef.current.clientHeight;

    // count++
    // console.log(count + ' ' + this.imageRef.current.clientHeight + ' ' + this.imageRef.current.offsetTop)

    const spans = Math.ceil(height / 10);

    this.setState({ spans });
  };

  // // onError={this.addDefaultSrc}
  // addDefaultSrc(ev) {
  //   // ev.target.src = 'bild/book_sign_ico.ico'
  //   ev.target.style.display = 'text'
  // }

  render() {
    const {
      id,
      login,
      usr,
      grp,
      url_usr,
      url_grp,
      ulclass,
      urls,
      type,
      pool_type,
      username,
      description,
      timeago,
      comments,
      page,
      sok
    } = this.props.image;
    const back_id = 1
    const max_post_id = 2
    const clear_post_id = 3
    const register_id = 4
    const articles_id = 5
    const images_id = 6
    const comments_id = 7
    var user = ''
    var furl = ''
    var sokstr = ''
    var sokstr_photo = ''
    var type_id = ''
    var curr_page = ''
    var max_type = 2
    var comment = ''
    var usersign = ''
    var txtstyle1 = {
      fontSize: 16,
      color: '#c8c8c8'
    }
    const txtstyle2 = {
      color: '#bfda55'
    }
    const txtstyle3 = {
      color: '#93ae26'
    }
    var txtstyle4 = {
      color: '#93ae26'
    }
    const txtstyle5 = {
      color: '#d25960'
    }
    var txtstyle6 = {
      fontSize: 16,
      color: '#888'
    }
    var txtstyle7 = {
      fontSize: 14,
      color: '#888'
    }

    if (comments) {
      comment = '(' + comments + ')'
    }
    if (login === usr && ulclass === 24) {
      txtstyle4 = {
        fontSize: 16,
        color: 'rgb(0,150,150)'
      }
    } else if (login === usr && ulclass === 25) {
      txtstyle4 = {
        fontSize: 16,
        color: '#d25960'
      }
    } else if (login === usr && ulclass === 29) {
      txtstyle4 = {
        fontSize: 16,
        color: '#950000'
      }
    }
    if (username.length) {
      usersign = '#'
    }

    if (url_usr) {
      user = 'us=' + url_usr + '&'
    }
    if (sok) {
      sokstr = '&sh=' + sok
      sokstr_photo = '&sst=' + sok
    }
    if (page > 1) {
      curr_page = page - 1
    }
    if (!url_usr && !url_grp) {
      max_type = 3
    }
    if (type) {
      type_id = type
    }

    if (id === register_id) {
      furl = 'https://usr.likethai.com/about?us=' + usr
    }
    else if (id === back_id) {
      if (url_grp && type) {
        furl = 'https://www.likethai.com/user/?grp=' + url_grp + '&up=5' + sokstr
      } else if (url_usr && type === 1) {
        furl = 'https://www.likethai.com/user/?us=' + url_usr + '&up=5' + sokstr
      } else {
        furl = 'https://www.likethai.com/user/?us=' + url_usr + sokstr
      }
    }
    else if (id === articles_id) {
      furl = 'https://host.likethai.com/?us=' + usr
    }
    else if (id === images_id) {
      furl = 'https://host.likethai.com/?us=' + usr + '&tp=1'
    }
    else if (id === comments_id) {
      furl = 'https://host.likethai.com/?us=' + usr + '&tp=3'
    }
    else if (id === max_post_id) {
      if (url_grp && type) {
        furl = 'https://host.likethai.com/?gr=' + url_grp + '&tp=' + type + '&pg=' + page + sokstr
        // } else if (url_usr && type === 1 && pool_type === 1) {
        //   furl = 'https://host.likethai.com/?us=' + url_usr + '&tp=5&pg=' + page + sokstr
      } else if (url_usr && type) {
        furl = 'https://host.likethai.com/?us=' + url_usr + '&tp=' + type + '&pg=' + page + sokstr
      } else if (url_usr) {
        furl = 'https://host.likethai.com/?us=' + url_usr + '&pg=' + page + sokstr
        // } else if (type === 1 && pool_type === 1) {
        //   furl = 'https://host.likethai.com/?tp=5&pg=' + page + sokstr
      } else if (type) {
        furl = 'https://host.likethai.com/?tp=' + type + '&pg=' + page + sokstr
      } else {
        furl = 'https://host.likethai.com/?pg=' + page + sokstr
      }
    }
    else if (id === clear_post_id) {
      if (url_grp && type) {
        furl = 'https://host.likethai.com/?gr=' + url_grp + '&tp=' + type
      } else if (url_usr && type) {
        furl = 'https://host.likethai.com/?us=' + url_usr + '&tp=' + type
      } else if (url_usr) {
        furl = 'https://host.likethai.com/?us=' + url_usr
      } else if (type) {
        furl = 'https://host.likethai.com/?tp=' + type
      } else {
        furl = 'https://host.likethai.com/'
      }
    }
    else if (grp && ulclass === 36 && type === 1) {
      furl = 'https://www.likethai.com/user/photo.php?up=5&grp=' + grp + '&lid=' + id + '&max=' + max_type + '&pg=' + curr_page + sokstr_photo
      // } else if (type === 1 && pool_type === 1) {
      //   furl = urls
    } else if (type === 1 && pool_type === 1 && id > 10300) {
      furl = 'https://usr.likethai.com/file?' + user + 'id=' + id + '&tp=' + type_id + '&pg=' + curr_page + sokstr
    } else if (type === 1) {
      furl = 'https://www.likethai.com/user/photo.php?us=' + usr + '&lid=' + id + '&max=' + max_type + '&pg=' + curr_page + sokstr_photo
      //show ulclass 24 in search and with id
      // } else if (!login && ulclass > 23) {
      //   furl = ''
    } else {
      furl = 'https://usr.likethai.com/article?' + user + 'id=' + id + '&tp=' + type_id + '&pg=' + curr_page + sokstr
    }

    var usurl = ''
    if (type) {
      usurl = 'https://host.likethai.com/?us=' + usr + '&tp=1'
    } else {
      usurl = 'https://host.likethai.com/?us=' + usr
    }

    var imgstyle = undefined
    if (id === max_post_id || id === clear_post_id) {
      imgstyle = {
        marginTop: 50,
        marginBottom: 20
      }
    } else if (urls) {
      imgstyle = {
        marginTop: 0,
        marginBottom: 20,
        fontSize: 16
      }
    } else {
      imgstyle = {
        marginTop: 0,
        marginBottom: 14,
        fontSize: 16
      }
    }

    // console.log(this)

    // return (
    //   <div className="img-text-wrapper" style={{ gridRowEnd: `span ${this.state.spans}` }} >
    //     <div className="container" style={imgstyle}>
    //       <a href={furl}>
    //         <img ref={this.imageRef} title={description} alt={description} src={urls}
    //         />
    //         <div className="content">
    //           <a style={txtstyle1} href={furl}>{description}</a> <a title="Comments" style={txtstyle2} href={furl}>{comment}</a> <a title={username} style={txtstyle4} href={usurl}>{usersign}</a><a title={username} style={txtstyle3} href={usurl}>{username}</a>
    //         </div>
    //       </a>
    //     </div>
    //   </div >

    //show ulclass 24 in search and with id
    // if (!login && ulclass === 24) {
    //   return (
    //     <div className="img-text-wrapper" style={{ gridRowEnd: `span ${this.state.spans}` }} >
    //       <div className="container" style={imgstyle}>
    //         <img ref={this.imageRef} title={description} alt={description} src={urls}
    //         />
    //         <div className="content">
    //           <span style={txtstyle6}>{description}</span><br /><span title={username} style={txtstyle4}>{usersign}</span><span title={username} style={txtstyle3}>{username}</span> <span title="Login" style={txtstyle5}>(Login)</span>
    //         </div>
    //       </div>
    //     </div >
    //   );
    // } else 
    if (id < 10) {
      return (
        <div className="img-text-wrapper" style={{ gridRowEnd: `span ${this.state.spans}` }} >
          <div className="container" style={imgstyle}>
            <a href={furl}>
              <img ref={this.imageRef} title={description} alt={description} src={urls}
              />
            </a>
            <div className="content">
              <a style={txtstyle1} href={furl}>{description}</a> <a title={username} style={txtstyle4} href={usurl}>{usersign}</a><a title={username} style={txtstyle3} href={usurl}>{username}</a>
            </div>
            {/* </a> */}
          </div>
        </div >
      );
    } else {
      if (type === 1 && url_usr && description.length) {
        return (
          <div className="img-text-wrapper" style={{ gridRowEnd: `span ${this.state.spans}` }} >
            <div className="container" style={imgstyle}>
              <a href={furl}>
                <img ref={this.imageRef} title={description} alt={description} src={urls}
                />
              </a>
              <div className="content">
                <a style={txtstyle1} href={furl}>{description}</a> <a title="Comments" style={txtstyle2} href={furl}>{comment}</a>
              </div>
              {/* </a> */}
            </div>
          </div >
        );
      } else if (type === 1 && (url_usr || (grp && ulclass === 36 && type === 1)) && !description.length) {
        return (
          <div className="img-text-wrapper" style={{ gridRowEnd: `span ${this.state.spans}` }} >
            <div style={imgstyle}>
              <a href={furl}>
                <img ref={this.imageRef} title={description} alt={description} src={urls}
                />
              </a>
            </div>
          </div >
        );
      } else {
        return (
          <div className="img-text-wrapper" style={{ gridRowEnd: `span ${this.state.spans}` }} >
            <div className="container" style={imgstyle}>
              <a href={furl}>
                <img ref={this.imageRef} title={description} alt={description} src={urls}
                />
              </a>
              <div className="content">
                <a style={txtstyle1} href={furl}>{description}</a> <a title="Comments" style={txtstyle2} href={furl}>{comment}</a><br /><a title={username} style={txtstyle4} href={usurl}>{usersign}</a><a title={username} style={txtstyle3} href={usurl}>{username}</a> <a style={txtstyle7} href={furl}>{timeago}</a>
              </div>
              {/* </a> */}
            </div>
          </div >
        );
      }
    }
  }
}

export default ImageCard;