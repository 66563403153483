import React from 'react';
// import Cookies from 'js-cookie';
// import unsplash from '../api/unsplash';
// import { fetchPosts } from '../actions'
import SearchBar from './SearchBar';
import ImageList from './ImageList';
// import MoreBar from './MoreBar';
// import LoginBar from './LoginBar';
import serverPlaceHolder from '../api/serverPlaceHolder';

// console.log('React App')

class App extends React.Component {
  state = {
    images: [],
    loading: true
  };

  onSearchSubmit = async (term) => {

    // console.log('onSearchSubmit')
    // console.log(window.location.pathname)
    // console.log(window.location.search)

    var lib = '/user'

    // console.log('onSearchSubmit ' + term)
    // const response = await serverPlaceHolder.get(lib + '?sh=' + term);

    // var pgstart = window.location.search.indexOf('pg')
    // var len = window.location.search.length

    // if (pgstart > 0) {
    //   var url = window.location.search.substr(0, (len - (len - pgstart)))
    //   window.location.href = url;
    // }

    // const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vw = document.documentElement.clientWidth

    var response = undefined
    if (window.location.search && term) {
      response = await serverPlaceHolder.get(lib + window.location.search + '&vw=' + vw + '&sq=1&shsub=' + term);
    } else {
      response = await serverPlaceHolder.get(lib + '?sq=1&vw=' + vw + '&sh=' + term);
    }

    // console.log('App response')
    // console.log(response.data)
    // console.log(window.location.search)

    this.setState({
      images: response.data
    });
  }

  async componentDidMount() {
    // console.log('componentDidMount')
    // console.log(window.location.search)

    var lib = '/user'

    // const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vw = document.documentElement.clientWidth
    // const token = Cookies.get('cookie')
    // console.log(token)

    // console.log(document.documentElement.clientWidth + ' ' + window.innerWidth + ' ' + vw)

    // this.fakeRequest().then(() => {
    // const el = document.querySelector(".loader-container");
    // if (el) {
    //   el.remove();  // removing the spinner element        
    //   this.setState({ loading: false }); // showing the app      
    // }
    // });

    var response = undefined
    if (window.location.search) {
      response = await serverPlaceHolder.get(lib + window.location.search + '&vw=' + vw + '&sh=');
    } else {
      response = await serverPlaceHolder.get(lib + '?sh=&vw=' + vw);
    }

    const el = document.querySelector(".loader-container");
    if (el) {
      el.remove();  // removing the spinner element        
      this.setState({ loading: false }); // showing the app      
    }

    // console.log(response.data[0])
    this.setState({
      images: response.data
    });
  }

  // fakeRequest = () => {
  //   return new Promise(resolve => setTimeout(() => resolve(), 0));
  // };

  // onMoreSubmit = async (term) => {

  //   console.log('onMoreSubmit')
  //   // console.log(window.location.pathname)
  //   // console.log(window.location.search)
  //   // console.log(term)

  //   var pgstart = window.location.search.indexOf('pg')
  //   var len = window.location.search.length
  //   var page = window.location.search.substr((pgstart + 3), 5)

  //   page = Number(page) + 1
  //   if (page > 2) {
  //     var url = window.location.search.substr(0, (len - (len - pgstart)))
  //     window.location.href = url + 'pg=' + page;
  //   } else if (len) {
  //     window.location.href = window.location.search + '&pg=2';
  //   } else {
  //     window.location.href = window.location.search + '?pg=2';
  //   }
  // }

  // onLoginSubmit = async (term) => {

  //   console.log('onLoginSubmit')
  // }

  render() {
    if (this.state.loading) {
      return null; //app is not ready (fake request is in process)    
    }

    return (
      <div className="ui container" style={{ marginTop: '10px' }}>
        <SearchBar onSubmit={this.onSearchSubmit} />
        <ImageList images={this.state.images} />
        {/* <MoreBar onSubmit={this.onMoreSubmit} /> */}
        <div style={{ fontSize: '12px', marginTop: '10px', marginBottom: '10px' }}><a href="https://www.likethai.com">www.likethai.com</a></div>
      </div>
    );
  }
}

export default App;